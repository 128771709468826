<template>
  <div class="questionsList">
    <van-loading size="18" color="#1989fa" v-if="showLoading" style="text-align: center;margin-top:50px;" />
    <template v-else>
      <van-divider v-if="isControlTime || isControlSameTime">
        计时
        <van-count-down :time="time" @finish="autoSubmit" @change="changeTime" :style="countTimeRed ? 'color:red' : ''" />
      </van-divider>
      <!-- 考题 -->
      <div class="main">
        <div class="questions">
          <div class="title">
            <em v-if="currentQuesrion.QuestionType === 'SingleChoice'">单选</em>
            <em v-else-if="currentQuesrion.QuestionType === 'MultiChoice'">多选</em>
            <em v-else-if="currentQuesrion.QuestionType === 'Judge'">判断</em>
            <em v-else-if="currentQuesrion.QuestionType === 'FillIn'">填空</em>
            <em v-else-if="currentQuesrion.QuestionType === 'QuestionAndAnswer'">问答</em>
            <span v-if="currentQuesrion.OrderIndex">{{ currentQuesrion.OrderIndex }}、</span>
            <span v-html="currentQuesrion.QuestionContent" style="word-break: break-all;"></span>
            <i v-if="currentQuesrion.MaxScore">{{ currentQuesrion.MaxScore }}分</i>
          </div>
          <div class="answer">
            <ul>
              <!-- 单选-->
              <template v-if="currentQuesrion.QuestionType == 'SingleChoice'">
                <li v-for="q in currentQuesrion.ChoiceItems" :key="q.ID" @click="goSelect(currentQuesrion.QuestionType, q)">
                  <em :class="{ on: currentAnswer.indexOf(q.ID) !== -1 }">{{ q.ItemCode }}</em>
                  <span v-html="q.ItemContent"></span>
                </li>
              </template>
              <!-- 多选 -->
              <template v-if="currentQuesrion.QuestionType == 'MultiChoice'">
                <li v-for="q in currentQuesrion.ChoiceItems" :key="q.ID" @click="goSelect(currentQuesrion.QuestionType, q)">
                  <em :class="{ on: currentAnswer.indexOf(q.ID) !== -1 }">{{ q.ItemCode }}</em>
                  <span v-html="q.ItemContent"></span>
                </li>
              </template>
              <!-- 判断 -->
              <template v-if="currentQuesrion.QuestionType == 'Judge'">
                <li v-for="q in currentQuesrion.JudgeItems" :key="q.ID" @click="goSelect(currentQuesrion.QuestionType, q)">
                  <em :class="{ on: currentAnswer.indexOf(q.ID) !== -1 }">{{ q.ItemCode }}</em>
                  <span v-html="q.ItemContent"></span>
                </li>
              </template>
              <!-- 填空 -->
              <template v-else-if="currentQuesrion.QuestionType == 'FillIn'">
                <li v-for="q in currentQuesrion.FillInItems" :key="q.ID">
                  <em>{{ q.ItemCode }}</em>
                  <van-field v-model="q.SubmitAnswer" placeholder="" @focus="setInput" />
                </li>
              </template>
              <!-- 问答题 -->
              <template v-else-if="currentQuesrion.QuestionType == 'QuestionAndAnswer'">
                <li>
                  <van-field v-model="currentQuesrion.SubmitContent" rows="3" autosize @focus="setInput" type="textarea" placeholder="" />
                </li>
              </template>
            </ul>
          </div>
          <div class="mark">
            <em @click="goMark" :style="{ color: currentQuesrion.isMark ? '#EE7805' : '#0066B3' }">
              <i class="iconfont iconbiaoji"></i>{{ currentQuesrion.isMark ? "取消标记" : "标记" }}</em>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="mark" @click="setCard">
          <i class="iconfont icondatika"></i> {{ currentNum + 1 }}/{{ questionsList.length }}
        </div>
        <div class="btn">
          <button @click="prev" :disabled="prevDisabled">上一题</button>
          <button @click="next" :disabled="nextDisabled">下一题</button>
          <button @click="submitAnswers">提交</button>
        </div>
      </div>
      <!-- 答题卡 -->
      <AnswerCard :questionsList="questionsList" :AnswersList="AnswersList" />
    </template>
  </div>
</template>

<script>
import { CountDown, Divider, Field, Dialog, Toast, Loading } from "vant";
import AnswerCard from "./components/AnswerCard.vue";
import { questions, examAnswer, examSubmit, examUpdateTime } from "@/api/exam.api.js";
import { mapState, mapMutations, mapActions } from "vuex";
import { debounce } from "../../utils/index.js";
import dayjs from "dayjs";
export default {
  name: "questionsList",
  components: {
    "van-count-down": CountDown,
    "van-divider": Divider,
    "van-field": Field,
    "van-loading": Loading,
    [Dialog.Component.name]: Dialog.Component,
    AnswerCard
  },
  data () {
    return {
      arrangeId: null,
      userExamMapId: null,
      userExamId: null,
      uniqueId: null,
      //以上是接口入参
      showLoading: false,
      questionsList: [],
      time: 0,
      currentQuesrion: {}, //当前题目
      prevDisabled: true,
      nextDisabled: false,
      AnswersList: [],
      currentAnswer: [],
      isAmend: false, // 答案是否修改(修改才调单题接口)
      isControlTime: null, //考试倒计时
      isControlSameTime: null, // 考试设置统一交卷时间
      updateTime: 2 * 60 * 1000,
      countTimeRed: false, //最后3分钟 倒计时变红
      submitType: null // 考试提交方式
    };
  },

  computed: {
    ...mapState(["cardShow", "questionsNum", "currentNum"])
  },
  watch: {
    //答题卡跳题
    currentNum () {
      this.currentQuesrion = this.questionsList[this.currentNum];
      this.prevDisabled = !this.currentNum;
      this.nextDisabled = this.currentNum == this.questionsList.length - 1 ? true : false;
      this.backfill();
      console.log("currentQuesrion-------000", this.currentQuesrion);
    },
    //答题卡显示的时候存值
    cardShow (n, o) {
      if (n == true && this.isAmend) {
        this.storageAnswer();
      }
    }
  },
  created () {
    this.arrangeId = this.$route.query.arrangeId;
    this.userExamMapId = this.$route.query.userExamMapId;
    this.init();
  },
  mounted () { },
  methods: {
    init () {
      this.getQuestions();
      this.updateTimer = setInterval(() => {
        this.getUserTime();
      }, this.updateTime);
    },
    ...mapMutations(["updateCard", "updateCurrentNum"]),
    ...mapActions(["updateQuestionsNumAsync"]),
    changeTime (e) {
      let { days, hours, minutes, seconds } = e;
      // 时间换成秒
      let totalSeconds = days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;
      this.countTimeRed = totalSeconds < 3 * 60 ? true : false;
    },
    getUserTime () {
      let data = {
        uniqueId: this.uniqueId,
        usedTime: this.updateTime / 1000
      };
      examUpdateTime(this.userExamId, this.arrangeId, this.userExamMapId, data)
        .then(res => { })
        .catch(err => { });
    },
    setCard () {
      this.updateCard(true);
    },
    getQuestions () {
      this.showLoading = true;
      questions(this.arrangeId, this.userExamMapId)
        .then(res => {
          this.showLoading = false;
          this.userExamId = res.userExamId;
          this.uniqueId = res.uniqueId;
          //控制考试时间
          if (res.isControlTime || res.isControlSameTime) {
            this.isControlTime = res.isControlTime;
            this.isControlSameTime = res.isControlSameTime;
            if (res.residueTimeType === 0) {
              //考试设置倒计时时间
              this.submitType = 1;
            } else if (res.residueTimeType === 1) {
              //统一交卷时间倒计时时间
              this.submitType = 2;
            }
            if (res.residueTimeStr && res.residueTimeStr.split(":").length > 1) {
              let tempTime = res.residueTimeStr.split(":");
              let hour = parseInt(tempTime[0]);
              let minute = parseInt(tempTime[1]);
              let second = parseInt(tempTime[2]);
              this.time = (hour * 60 * 60 + minute * 60 + second) * 1000; //交卷倒计时(毫秒)
            } else {
              this.time =
                parseInt(res.residueTimeStr) > 0
                  ? parseInt(res.residueTimeStr) * 60 * 60 * 1000
                  : 0;
            }
          }
          let list = JSON.stringify(res.combinedQuestions);
          this.questionsList = JSON.parse(list);
          console.log("currentNum=", this.currentNum);
          console.log("questionsList=", this.questionsList);
          this.currentQuesrion = this.questionsList[this.currentNum];

          // 回填暂存的答案
          this.questionsList.forEach((e, index) => {
            let answer = [];
            if (e.QuestionType == "SingleChoice" || e.QuestionType == "MultiChoice") {
              e.ChoiceItems &&
                e.ChoiceItems.forEach((i, idx) => {
                  if (i.IsSelected) {
                    answer.push(i.ID);
                  }
                });
            } else if (e.QuestionType == "Judge") {
              e.JudgeItems &&
                e.JudgeItems.forEach((i, idx) => {
                  if (i.IsSelected) {
                    answer.push(i.ID);
                  }
                });
            } else if (e.QuestionType == "FillIn") {
              e.FillInItems &&
                e.FillInItems.forEach((i, idx) => {
                  answer.push(i.SubmitAnswer);
                });
            } else if (e.QuestionType == "QuestionAndAnswer") {
              if (e.SubmitContent) {
                answer.push(e.SubmitContent);
              }
            }
            if (
              answer.length !== 0 &&
              answer.some(n => {
                return n !== "" && n != null;
              })
            ) {
              let answerObj = {
                answer: answer,
                attach: [],
                index: index + 1,
                questionId: e.ID,
                questionType: e.QuestionType
              };
              this.AnswersList.push(answerObj);
            }
          });
          this.backfill();

          this.updateQuestionsNumAsync(res.combinedQuestions.length);
        })
        .catch(err => {
          if (err.status === 400) {
            this.$router.replace({ path: "/exam/introduce", query: { arrangeId: this.arrangeId } });
          }
        });
    },
    setInput () {
      this.isAmend = true;
    },
    //存答案
    storageAnswer () {
      this.isAmend = false;
      console.log("this.currentQuesrion-----", this.currentQuesrion);
      if (this.currentQuesrion.QuestionType == "FillIn") {
        this.currentAnswer = this.currentQuesrion.FillInItems.map(e => {
          return e.SubmitAnswer;
        });
      } else if (this.currentQuesrion.QuestionType == "QuestionAndAnswer") {
        this.currentAnswer.unshift(this.currentQuesrion.SubmitContent);
      }
      // 先检查AnswersList有没有这个问题的答案,有就修改,没有就新增
      let index = this.AnswersList.findIndex(e => {
        return e.questionId === this.currentQuesrion.ID;
      });
      if (index === -1) {
        //新增
        //新增时,答案有值并且不全为空或null时,才存到接口里
        if (
          this.currentAnswer.length !== 0 &&
          this.currentAnswer.some(n => {
            return n !== "" && n !== null;
          })
        ) {
          let answer = {
            answer: this.currentAnswer,
            attach: [],
            index: this.currentNum + 1,
            questionId: this.currentQuesrion.ID,
            questionType:
              this.currentQuesrion.QuestionType === "QuestionAndAnswer"
                ? "QuestionAnswer"
                : this.currentQuesrion.QuestionType
          };
          //单题存答案
          examAnswer(this.userExamId, this.arrangeId, this.userExamMapId, { answers: [answer] })
            .then(() => { })
            .catch(() => { });
          this.AnswersList.push(answer);
        }
      } else {
        // 修改时,值可以为空
        let answer = {
          answer: this.currentAnswer,
          attach: [],
          index: this.currentNum + 1,
          questionId: this.currentQuesrion.ID,
          questionType:
            this.currentQuesrion.QuestionType === "QuestionAndAnswer"
              ? "QuestionAnswer"
              : this.currentQuesrion.QuestionType
        };
        //单题存答案
        examAnswer(this.userExamId, this.arrangeId, this.userExamMapId, { answers: [answer] })
          .then(() => { })
          .catch(() => { });
        if (this.currentAnswer.join("") === "") {
          this.AnswersList.splice(index, 1);
        } else {
          this.AnswersList[index] = answer;
        }
      }
    },
    //上一题,下一题 回填
    backfill () {
      let item = this.AnswersList.find(e => {
        return e.questionId == this.questionsList[this.currentNum].ID;
      });
      if (item) {
        this.currentAnswer = item.answer;
      } else {
        this.currentAnswer = [];
      }
      console.log("this.currentAnswer----------------------2", this.currentAnswer);
    },
    prevFun () {
      // 点上一题之前,存答案
      if (this.isAmend) {
        this.storageAnswer();
      }
      this.nextDisabled = false;
      this.updateCurrentNum(this.currentNum - 1);
      this.currentQuesrion = this.questionsList[this.currentNum];
      if (this.currentNum === 0) {
        this.prevDisabled = true;
        // return
      }
      this.backfill();
    },
    nextFun () {
      // 点下一题之前,存答案
      if (this.isAmend) {
        this.storageAnswer();
      }
      this.prevDisabled = false;
      this.updateCurrentNum(this.currentNum + 1);
      this.currentQuesrion = this.questionsList[this.currentNum];
      if (this.questionsList.length - 1 === this.currentNum) {
        this.nextDisabled = true;
        // return
      }
      this.backfill();
    },
    //防抖处理
    prev: debounce("prevFun", 1000),
    next: debounce("nextFun", 1000),
    goMark () {
      this.$forceUpdate();
      this.questionsList[this.currentNum]["isMark"] =
        this.questionsList[this.currentNum] && this.questionsList[this.currentNum].isMark
          ? false
          : true;
      this.currentQuesrion = this.questionsList[this.currentNum];
    },
    goSelect (type, q) {
      this.isAmend = true;
      if (type === "SingleChoice" || type === "Judge") {
        // 单选 或 判断
        if (this.currentAnswer.indexOf(q.ID) !== -1) {
          this.currentAnswer.splice(this.currentAnswer.indexOf(q.ID), 1); //取消
        } else {
          this.currentAnswer.unshift(q.ID); //选中添加到数组里
          this.currentAnswer.splice(1, this.currentAnswer.length - 1);
        }
      } else if (type === "MultiChoice") {
        // 多选
        if (this.currentAnswer.indexOf(q.ID) !== -1) {
          this.currentAnswer.splice(this.currentAnswer.indexOf(q.ID), 1); //取消
        } else {
          this.currentAnswer.push(q.ID); //选中添加到数组里
        }
      }
      console.log("currentAnswer---------", this.currentAnswer);
    },
    /**
     * submitType
     * 0：正常提交，
     * 1：考试时长用完自动提交，
     * 2：统一交卷时间到自动提交，
     * 3：结束考试后台强制提交
     */
    //倒计时间结束和统一交卷 自动提交
    autoSubmit () {
      let data = {
        submitType: this.submitType,
        uniqueId: this.uniqueId,
        usedTime: 0,
        answers: this.AnswersList
      };
      this.storageAnswer();
      Toast.loading({
        message: `考试时间已结束,试卷提交中`,
        duration: 3000,
        forbidClick: true
      });
      setTimeout(() => {
        this.goSubmit(data);
      }, 3000);
    },
    // 用户点击提交按钮提交
    submitAnswers () {
      let data = {
        submitType: 0,
        uniqueId: this.uniqueId,
        usedTime: 0,
        answers: this.AnswersList
      };
      this.storageAnswer();
      let num = this.questionsList.length - this.AnswersList.length;
      let msg = num ? `还有${num} 题未答完，确认提交？` : `确认提交?`;
      Dialog.confirm({
        title: "温馨提示",
        message: msg
      })
        .then(() => {
          Toast.loading({
            message: `试卷提交中`,
            duration: 3000,
            forbidClick: true
          });
          setTimeout(() => {
            this.goSubmit(data);
          }, 3000);
        })
        .catch(() => { });
    },
    //提交试卷接口
    goSubmit (data) {
      examSubmit(this.userExamId, this.arrangeId, this.userExamMapId, data)
        .then(() => { })
        .catch(err => {
          if (err.status == 204) {
            this.$router.replace({
              path: "/exam/commit",
              query: {
                userExamId: this.userExamId,
                arrangeId: this.arrangeId,
                userExamMapId: this.userExamMapId
              }
            });
            setTimeout(() => {
              //数据全初始化
              this.updateCurrentNum(0);
              this.questionsList = [];
              this.AnswersList = [];
              this.currentAnswer = [];
            }, 3000);
          } else if (err.status == 400) {
            this.$router.go(-1);
          }
        });
    }
  },
  beforeDestroy () {
    this.updateCurrentNum(0);
    clearInterval(this.updateTimer);
  }
};
</script>

<style lang="less" scoped>
.questionsList {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .main {
    flex: 1;
    width: 100%;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .van-divider {
    color: @blueTheme;
    border-color: #d8d8d8;
    margin: 46px 30px 0px 30px;
    .van-count-down {
      color: @blueTheme;
      margin-left: 20px;
      width: 120px;
    }
  }
  .questions {
    margin: 0 30px;
    font-size: 32px;
    color: @color3;
    .title {
      padding-top: 40px;
      line-height: 53px;
      letter-spacing: 2px;
      text-align: justify;
      /deep/span div {
        display: inline;
      }
      em {
        padding: 0 15px;
        height: 40px;
        background: #e6f4ff;
        border-radius: 2px 100px 100px 2px;
        font-size: 22px;
        color: @blueTheme;
        display: inline-block;
        line-height: 39px;
        text-align: center;
        margin-right: 30px;
      }
      i {
        width: 70px;
        height: 40px;
        display: inline-block;
        background: #e6f4ff;
        border: 1px solid @blueTheme;
        border-radius: 4px;
        font-size: 24px;
        color: @blueTheme;
        line-height: 40px;
        text-align: center;
        margin-left: 10px;
      }
    }
    .answer {
      padding-top: 30px;
      font-size: 28px;
      color: @color3;
      li {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        span {
          line-height: 40px;
          word-break: break-all;
        }
        em {
          min-width: 54px;
          height: 54px;
          line-height: 54px;
          text-align: center;
          font-size: 28px;
          color: @color3;
          display: inline-block;
          background: #ffffff;
          border-radius: 50%;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.17);
          margin-right: 20px;
          &.on {
            background: #41c160;
            color: #fff;
          }
        }
        .van-cell {
          border: 1px solid #979797;
          border-radius: 4px;
          padding: 10px;
        }
      }
    }
    .van-field {
      border: 1px solid #979797;
      border-radius: 4px;
    }
    .mark {
      margin: 70px 0;
      font-size: 28px;
      color: @blueTheme;
      display: flex;
      em {
        display: inline-block;
        margin-left: auto;
        i {
          margin-right: 10px;
        }
      }
    }
  }
  .footer {
    border-top: 1px solid #d8d8d8;
    padding: 20px 30px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .mark {
      font-size: 28px;
      color: @color6;
      margin-top: 20px;
      i {
        color: @blueTheme;
        font-size: 30px;
      }
    }
    .btn {
      display: inline-block;
      vertical-align: baseline;
      button {
        height: 60px;
        line-height: 60px;
        font-size: 28px;
        // color: @color3;
        border: none;
        outline: none;
        border-radius: 30px;
        background: #fff;
        display: inline-block;
        margin-left: 20px;
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
        padding: 0 40px;
        color: @blueTheme;
        &:active {
          color: #fff;
          background: @blueTheme;
        }
        &:disabled {
          color: #999999;
        }
      }
    }
  }
}
</style>
