var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "questionsList" },
    [
      _vm.showLoading
        ? _c("van-loading", {
            staticStyle: { "text-align": "center", "margin-top": "50px" },
            attrs: { size: "18", color: "#1989fa" }
          })
        : [
            _vm.isControlTime || _vm.isControlSameTime
              ? _c(
                  "van-divider",
                  [
                    _vm._v(" 计时 "),
                    _c("van-count-down", {
                      style: _vm.countTimeRed ? "color:red" : "",
                      attrs: { time: _vm.time },
                      on: { finish: _vm.autoSubmit, change: _vm.changeTime }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "main" }, [
              _c("div", { staticClass: "questions" }, [
                _c("div", { staticClass: "title" }, [
                  _vm.currentQuesrion.QuestionType === "SingleChoice"
                    ? _c("em", [_vm._v("单选")])
                    : _vm.currentQuesrion.QuestionType === "MultiChoice"
                    ? _c("em", [_vm._v("多选")])
                    : _vm.currentQuesrion.QuestionType === "Judge"
                    ? _c("em", [_vm._v("判断")])
                    : _vm.currentQuesrion.QuestionType === "FillIn"
                    ? _c("em", [_vm._v("填空")])
                    : _vm.currentQuesrion.QuestionType === "QuestionAndAnswer"
                    ? _c("em", [_vm._v("问答")])
                    : _vm._e(),
                  _vm.currentQuesrion.OrderIndex
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.currentQuesrion.OrderIndex) + "、")
                      ])
                    : _vm._e(),
                  _c("span", {
                    staticStyle: { "word-break": "break-all" },
                    domProps: {
                      innerHTML: _vm._s(_vm.currentQuesrion.QuestionContent)
                    }
                  }),
                  _vm.currentQuesrion.MaxScore
                    ? _c("i", [
                        _vm._v(_vm._s(_vm.currentQuesrion.MaxScore) + "分")
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "answer" }, [
                  _c(
                    "ul",
                    [
                      _vm.currentQuesrion.QuestionType == "SingleChoice"
                        ? _vm._l(_vm.currentQuesrion.ChoiceItems, function(q) {
                            return _c(
                              "li",
                              {
                                key: q.ID,
                                on: {
                                  click: function($event) {
                                    return _vm.goSelect(
                                      _vm.currentQuesrion.QuestionType,
                                      q
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "em",
                                  {
                                    class: {
                                      on: _vm.currentAnswer.indexOf(q.ID) !== -1
                                    }
                                  },
                                  [_vm._v(_vm._s(q.ItemCode))]
                                ),
                                _c("span", {
                                  domProps: { innerHTML: _vm._s(q.ItemContent) }
                                })
                              ]
                            )
                          })
                        : _vm._e(),
                      _vm.currentQuesrion.QuestionType == "MultiChoice"
                        ? _vm._l(_vm.currentQuesrion.ChoiceItems, function(q) {
                            return _c(
                              "li",
                              {
                                key: q.ID,
                                on: {
                                  click: function($event) {
                                    return _vm.goSelect(
                                      _vm.currentQuesrion.QuestionType,
                                      q
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "em",
                                  {
                                    class: {
                                      on: _vm.currentAnswer.indexOf(q.ID) !== -1
                                    }
                                  },
                                  [_vm._v(_vm._s(q.ItemCode))]
                                ),
                                _c("span", {
                                  domProps: { innerHTML: _vm._s(q.ItemContent) }
                                })
                              ]
                            )
                          })
                        : _vm._e(),
                      _vm.currentQuesrion.QuestionType == "Judge"
                        ? _vm._l(_vm.currentQuesrion.JudgeItems, function(q) {
                            return _c(
                              "li",
                              {
                                key: q.ID,
                                on: {
                                  click: function($event) {
                                    return _vm.goSelect(
                                      _vm.currentQuesrion.QuestionType,
                                      q
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "em",
                                  {
                                    class: {
                                      on: _vm.currentAnswer.indexOf(q.ID) !== -1
                                    }
                                  },
                                  [_vm._v(_vm._s(q.ItemCode))]
                                ),
                                _c("span", {
                                  domProps: { innerHTML: _vm._s(q.ItemContent) }
                                })
                              ]
                            )
                          })
                        : _vm.currentQuesrion.QuestionType == "FillIn"
                        ? _vm._l(_vm.currentQuesrion.FillInItems, function(q) {
                            return _c(
                              "li",
                              { key: q.ID },
                              [
                                _c("em", [_vm._v(_vm._s(q.ItemCode))]),
                                _c("van-field", {
                                  attrs: { placeholder: "" },
                                  on: { focus: _vm.setInput },
                                  model: {
                                    value: q.SubmitAnswer,
                                    callback: function($$v) {
                                      _vm.$set(q, "SubmitAnswer", $$v)
                                    },
                                    expression: "q.SubmitAnswer"
                                  }
                                })
                              ],
                              1
                            )
                          })
                        : _vm.currentQuesrion.QuestionType ==
                          "QuestionAndAnswer"
                        ? [
                            _c(
                              "li",
                              [
                                _c("van-field", {
                                  attrs: {
                                    rows: "3",
                                    autosize: "",
                                    type: "textarea",
                                    placeholder: ""
                                  },
                                  on: { focus: _vm.setInput },
                                  model: {
                                    value: _vm.currentQuesrion.SubmitContent,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.currentQuesrion,
                                        "SubmitContent",
                                        $$v
                                      )
                                    },
                                    expression: "currentQuesrion.SubmitContent"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ]),
                _c("div", { staticClass: "mark" }, [
                  _c(
                    "em",
                    {
                      style: {
                        color: _vm.currentQuesrion.isMark
                          ? "#EE7805"
                          : "#0066B3"
                      },
                      on: { click: _vm.goMark }
                    },
                    [
                      _c("i", { staticClass: "iconfont iconbiaoji" }),
                      _vm._v(
                        _vm._s(_vm.currentQuesrion.isMark ? "取消标记" : "标记")
                      )
                    ]
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "footer" }, [
              _c("div", { staticClass: "mark", on: { click: _vm.setCard } }, [
                _c("i", { staticClass: "iconfont icondatika" }),
                _vm._v(
                  " " +
                    _vm._s(_vm.currentNum + 1) +
                    "/" +
                    _vm._s(_vm.questionsList.length) +
                    " "
                )
              ]),
              _c("div", { staticClass: "btn" }, [
                _c(
                  "button",
                  {
                    attrs: { disabled: _vm.prevDisabled },
                    on: { click: _vm.prev }
                  },
                  [_vm._v("上一题")]
                ),
                _c(
                  "button",
                  {
                    attrs: { disabled: _vm.nextDisabled },
                    on: { click: _vm.next }
                  },
                  [_vm._v("下一题")]
                ),
                _c("button", { on: { click: _vm.submitAnswers } }, [
                  _vm._v("提交")
                ])
              ])
            ]),
            _c("AnswerCard", {
              attrs: {
                questionsList: _vm.questionsList,
                AnswersList: _vm.AnswersList
              }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }